export const delayed = (delay, xOffset) => {
  return {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.5,
        delay
      }
    },
    hidden: {
      opacity: 0,
      x: xOffset
    }
  }
}

export const fadeIn = (delay) => {
  return {
    visible: {
      opacity: 1,
      transition: {
        delay
      }
    },
    hidden: {
      opacity: 0,
    }
  }
}

export const growRight = {
  hidden: {
    width: 1,
  },
  visible: {
    width: "100%",
    transition: {
      delay: 0.3,
      duration: 0.5
    }
  }
}