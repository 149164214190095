import React from 'react';
import "../styles/components/shard.scss";
import tinycolor from 'tinycolor2';
import { motion, useAnimation } from "framer-motion";

const fadeUp = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      type: "spring",
      stiffness: 100,
    }
  },
  hidden: {
    y: 50,
    opacity: 0,
  }
}

function Shard({name, hex}) {

  return (
    <motion.div variants={fadeUp} className={`shard ${isDark(hex) ? '' : 'dark'}`} style={{ backgroundColor: hex }}>
      <p>
        {name}
      </p>
    </motion.div>
  );
}

function isDark(hex) {
  const color = tinycolor(hex);
  return color.getBrightness() < 170; // Super arbitrary number.
}

export default Shard;